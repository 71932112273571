var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"10px","font-family":"'Montserrat'","font-size":"15px"}},[_c('v-container',{staticClass:"mx-auto",staticStyle:{"margin-top":"1%","background-color":"","width":"95%"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticStyle:{"font-size":"20px"}},[_c('i',{staticClass:"far fa-envelope"})]),_c('v-btn',{staticStyle:{"font-size":"20px","margin-left":"10px"}},[_c('i',{staticClass:"fas fa-download"})])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"clave","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",staticStyle:{"display":"none"},attrs:{"label":"Single select"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}})]},proxy:true},{key:"item.agencia",fn:function(ref){
var item = ref.item;
return [(item.imgAgency)?_c('img',{staticStyle:{"width":"15px"},attrs:{"src":item.imgAgency}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.agencia))])]}},{key:"item.brokers",fn:function(ref){
var item = ref.item;
return [(item.img)?_c('img',{staticStyle:{"width":"15px"},attrs:{"src":item.img}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.brokers))])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.reformatDate(item.fecha)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatus(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1),_c('br')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }